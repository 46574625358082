import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint'
import Scrollspy from 'react-scrollspy'

import logo from '../assets/images/logo.png';

const HEADER_HEIGHT = '80px';

const NavLayout = styled.nav`
  --height: ${HEADER_HEIGHT};
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  height: var(--height);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 calc(var(--height) / 4);
  background-color: #000000AA; */

  /* > .links {
    > a {
      margin-left: 1em;
    }
  } */
`;

const Nav = ({ className = '', stickyNav }) => {
  return (
    <NavLayout id="nav" className={`${stickyNav ? 'alt' : ''} ${className}`}>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about/amafu">Amafu</Link>
        </li>
        <li>
          <Link to="/about/jikelele">Jikelele</Link>
        </li>
        <li>
          <Link to="/about/omnotho">Omnotho</Link>
        </li>
      </ul>
    </NavLayout>
  );
};

const Header = ({ className = '', title, subtitle = null, showLogo = false, hideNav = false }) => {
  const [stickyNav, setStickyNav] = useState<boolean>(false);

  return (
    <>
      <a id="top" />
      <header id="header" className={`alt ${className}`}>
        {showLogo && <span className="logo"><img src={logo} alt="logo" /></span>}
        <h1>{title}</h1>
        {subtitle && <p>{subtitle}</p>}
      </header>
      <Waypoint
        onEnter={() => setStickyNav(false)}
        onLeave={() => setStickyNav(true)}
      />
      {!hideNav && (<Nav stickyNav={stickyNav} />)}
    </>
  );
};

export default Header;
